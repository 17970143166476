.root {
    position: relative;
    display: inline-flex;
    justify-content: center;
}

.triggerIconWithLabel {
    svg {
        /* Adding 4px to remove the 4px margin right under Button-container svg style */
        margin-right: calc(-1rem * var(--font-ratio) + 4px) !important;
        transition: transform 0.1s ease-in-out;
        @media (prefers-reduced-motion) {
            transition: none;
        }
    }

    &[aria-expanded='true'] {
        svg {
            transform: rotate(180deg);
        }
    }
}

.popup {
    z-index: var(--z-modal);
    transition: transform 0.2s ease-in-out;
    @media (prefers-reduced-motion) {
        transition: none;
    }
}

.menu {
    box-shadow: var(--elevation-lvl-1);
    background-color: var(--bg-lvl-1-color);

    /*  Make enough space so that big action lists in the middle of the screen */

    /*  don't get their contents cut off. */
    max-height: calc(50vh - 4em);
    overflow-y: auto;
    border-radius: var(--border-overlay-radius);
}

.item {
    position: relative;
    min-width: calc(var(--base-unit) * 60);
    max-width: var(--readable-max-width);
    padding: var(--spacing-3) var(--spacing-4);
    transition: background 50ms linear;

    &:hover {
        background: var(--color-container-background-tertiary);
        transition: background 50ms linear;

        .label {
            color: var(--interactive-hover-color);
        }
    }

    &:focus-within {
        background: var(--bg-inverted-color);
        color: var(--text-inverted-color);

        .label,
        .description {
            color: inherit;
        }
    }
}

.disabled,
.disabled:hover {
    background: var(--bg-lvl-1-color);
    color: var(--disabled-color);
    cursor: not-allowed;

    .label,
    .description {
        color: inherit;
        cursor: inherit;
    }
}

.label {
    display: flex;
    align-items: center;

    width: 100%;
    font: var(--font-text-medium);
    overflow: hidden;
    color: var(--interactive-color);
    transition: color 50ms linear;


    /*  Spacing for label and icons */
    > * + * {
        margin-left: var(--spacing-1);
    }


    /*  Handled by .item's :focus-within. */
    &:focus {
        outline: none;
    }


    /*  Make the .label take up the whole space, including the .description. */
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.description {
    transition: color 50ms linear;
}

:not(.group) + .group {
    border-top: 1px solid var(--border-light-color);
}

.group:not(:last-child) {
    border-bottom: 1px solid var(--border-light-color);
    padding-bottom: var(--spacing-2);
}

.groupLabel {
    color: var(--text-secondary-color);
    font: var(--font-text-small);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    padding: var(--spacing-4) var(--spacing-4) var(--spacing-3);
    display: flex;
    word-break: break-word;
    position: sticky;
    top: 0;
    background-color: var(--bg-lvl-1-color);
    z-index: var(--z-base);
}

/******************
* PURPOSE
******************/

.passive {
    background-color: var(--bg-lvl-3-color);
    color: var(--text-primary-color);
    border-radius: var(--border-pill-radius);

    &:hover {
        background-color: var(--bg-lvl-4-color);
        color: var(--text-primary-color);
    }

    &:disabled,
    a&:not([href]) {
        opacity: var(--disabled-button-opacity);
        color: initial;
    }

    &.secondary {
        box-shadow:
            inset 0 0 0 var(--button-secondary-border-width)
            var(--bg-lvl-3-color);
        background-color: var(--button-secondary-background);
        text-decoration: none;

        &:hover {
            background-color: var(--bg-lvl-3-color);
            box-shadow:
                inset 0 0 0 var(--button-secondary-border-width)
                var(--brand-tuna); /*  no theming variable for tuna */
        }
    }

    &.tertiary {
        background-color: var(--button-secondary-background);
        text-decoration: none;

        &:hover {
            background-color: var(--bg-lvl-3-color);
        }
    }

    &.tertiary {
        background-color: var(--button-secondary-background);
        text-decoration: none;

        &:hover {
            background-color: var(--bg-lvl-3-color);
        }
    }
}

:global(.mcds-redesign-molecules) {
    .root {
        display: inline-flex;
        align-items: flex-start;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--radius-action);
    }

    .triggerIconWithLabel {
        font-weight: var(--font-weight-body);
        justify-content: center;
        align-items: center;

        svg {
            display: flex;
            width: calc(var(--base-unit) * 4);
            height: calc(var(--base-unit) * 4);
            margin-right: calc(-1rem * var(--font-ratio) + 8px) !important;
        }

        &[aria-expanded='true'] {
            svg { transform: none; }
        }
    }

    .menu {
        display: flex;
        min-width: calc(var(--base-unit) * 40);
        max-height: calc(var(--base-unit) * 90);
        flex-direction: column;
        align-items: flex-start;
        background: var(--color-container-background-primary);
        border-radius: var(--radius-action);
        box-shadow: 0 2px 8px 0 var(--color-shadow);
    }

    .item {
        display: flex;
        padding: var(--space-component-inline-padding-medium);
        align-items: center;
        gap: var(--space-x-small);
        min-width: calc(var(--base-unit) * 40);
        flex-direction: column;
        justify-content: center;
        align-self: stretch;

        .label {
            color: var(--color-text-primary);
            font-size: var(--font-size-component-small);
            line-height: var(--line-height-component);
        }

        &:focus-within {
            background: var(--color-action-standard-subtle-focus);
            color: var(--color-text-primary);

            .label,
            .description {
                color: inherit;
            }
        }
        &:hover {
            background: var(--bg-interactive-color);
            transition: background 50ms linear;

            .label {
                color: inherit;
            }
        }
    }

    .small {
        height: calc(var(--base-unit) * 6);
        gap: var(--space-component-gap-x-small);

        .triggerIconWithLabel {
            font-size: var(--font-size-component-x-small);
            line-height: var(--line-height-component);
        }
    }
    .medium {
        height: calc(var(--base-unit) * 8);
        gap: var(--space-component-gap-small);

        .triggerIconWithLabel {
            font-size: var(--font-size-component-small);
            line-height: var(--line-height-component);
        }
    }
    .large {
        height: calc(var(--base-unit) * 10);
        gap: var(--space-component-gap-small);

        .triggerIconWithLabel {
            font-size: var(--font-size-component-medium);
            line-height: var(--line-height-component);
        }
    }
    .primary {
        border-radius: var(--radius-action);
        background-color: var(--color-action-standard);
        &:hover {
            background-color: var(--color-action-standard-hover);
        }
        &:active {
            background-color: var(--color-action-standard-active);

        }
        &:focus-visible {
            background-color: var(--color-action-standard-focus);
            outline: 2px solid var(--color-ui-primary);
            outline-offset: 1px;
            border-radius: var(--radius-action);
        }
        &[disabled],
        &.disabled {
            opacity: var(--opacity-disabled);
            cursor: not-allowed;
        }
    }

    .secondary {
        color: var(--color-text-primary);
        background-color: var(--color-action-standard-subtle);
        border-radius: var(--radius-action);
        box-shadow: inset 0 0 0 var(--button-secondary-border-width) var(--color-container-border-secondary);

        &:hover {
            background-color: var(--color-action-standard-subtle-hover);
        }
        &:active {
            color: var(--color-text-primary);
            background-color: var(--color-action-standard-subtle-active);
        }
        &:focus-visible {
            color: var(--color-text-primary);
            background-color: var(--color-action-standard-subtle-hover);
            outline: 2px solid var(--color-ui-primary);
            outline-offset: 1px;
        }
        &[disabled],
        &.disabled {
            color: var(--color-text-primary);
            opacity: var(--opacity-disabled);
            cursor: not-allowed;
        }
    }

    .tertiary {
        color: var(--color-text-primary);
        background-color: var(--color-action-passive);
        border-radius: var(--radius-action);
        &:hover {
            color: var(--color-text-primary);
            background-color: var(--color-action-passive-hover);
        }
        &:active {
            color: var(--color-text-primary);
            background-color: var(--color-action-passive-active);
        }
        &:focus-visible {
            color: var(--color-text-primary);
            background-color: var(--color-action-passive-focus);
            outline: 2px solid var(--color-ui-primary);
            outline-offset: 1px;
        }
        &[disabled],
        &.disabled {
            color: var(--color-text-primary);
            opacity: var(--opacity-disabled);
            cursor: not-allowed;
        }
    }

    .quaternary {
        color: var(--color-text-primary);
        background-color: var(--color-action-passive-subtle);
        border-radius: var(--radius-action);
        &:hover {
            color: var(--color-text-primary);
            background-color: var(--color-action-passive-subtle-hover);
        }
        &:active {
            color: var(--color-text-primary);
            background-color: var(--color-action-passive-subtle-active);
        }
        &:focus-visible {
            color: var(--color-text-primary);
            background-color: var(--color-action-passive-subtle-focus);
            outline: 2px solid var(--color-ui-primary);
            outline-offset: 1px;
        }
        &[disabled],
        &.disabled {
            color: var(--color-text-primary);
            opacity: var(--opacity-disabled);
            cursor: not-allowed;
        }
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--radius-action);

        &[disabled],
        &.disabled,
        .disabled:hover {
            cursor: not-allowed;
            pointer-events: none;
        }

        .small {
            width: calc(var(--base-unit) * 6);
        }
        .medium {
            width: calc(var(--base-unit) * 8) !important;
            height: calc(var(--base-unit) * 8) !important;
        }
        .large {
            width: calc(var(--base-unit) * 10);
        }
        .primary {
            background-color: var(--color-action-standard);
            color: var(--color-icon-inverse);
            box-shadow: none;
            &:hover {
                background-color: var(--color-action-standard-hover);
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:focus-visible {
                border-radius: var(--radius-action);
                background-color: var(--color-action-standard-focus);
                outline: 2px solid var(--color-ui-primary);
                outline-offset: 1px;
                box-shadow: none;
            }
            &:active {
                background-color: var(--color-action-standard-active);
                box-shadow: none;
            }
        }
        .secondary {
            box-shadow: none;
            &:hover {
                background-color: var(--color-action-standard-subtle-hover);
            }
            &:active {
                background-color: var(--color-action-standard-subtle-active);
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:focus-visible {
                background-color: var(--color-action-standard-subtle-focus);
                outline: 2px solid var(--color-ui-primary);
                box-shadow: none;
                outline-offset: 1px;
            }
        }
        .tertiary {
            box-shadow: none;
            &:hover {
                background-color: var(--color-action-passive-hover);
            }
            &:active {
                background-color: var(--color-action-passive-active);
                box-shadow: none;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:focus-visible {
                background-color: var(--color-action-passive-focus);
                outline: 2px solid var(--color-ui-primary);
                outline-offset: 1px;
                box-shadow: none;
            }
        }
        .quaternary {
            box-shadow: none;
            &:hover {
                background-color: var(--color-action-passive-subtle-hover);
            }
            &:active {
                background-color: var(--color-action-passive-subtle-active);
                box-shadow: none;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:focus-visible {
                outline: none;
                outline: 2px solid var(--color-ui-primary);
                outline-offset: 1px;
                box-shadow: none;
            }
        }
    }
}
